jQuery( function(){		
	var updateSession = {
		timeout: 300000,
		request_page: '_reload_session.php',
		refresh: function() {
			var self = this;				
			jQuery.ajax({ 
				url: self.request_page, 
				complete: function(){
					self.doTimeout();
				} 
			});				
		},
		doTimeout: function() {
			var self = this;
			setTimeout( function(){
					self.refresh(); 	
				}, 
				this.timeout);
		}
	}
	updateSession.doTimeout();
});